import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import styled, { ThemeProvider } from 'styled-components';
import FocusLock from 'react-focus-lock';
import { makeStyles } from '@material-ui/core/styles';
import CookieConsent from 'react-cookie-consent';
import { useOnClickOutside } from '../hooks';
import { GlobalStyles } from '../global';
import {
  Burger, Menu, Footer, Header, HeaderLinks,
} from '.';
import theming from '../theme';

import SEO from './seo';

import './layout.css';

const LocaleContext = React.createContext();

const Wrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  /*min-width: 960px;*/
  background-color: #f0f0f0;

  @media only screen and (max-width: 600px){
      padding: 1rem;
      padding-top: 4rem;
  }
  @media only screen and (min-width: 600px) {
    /* For tablets: */
    padding: 1rem;
    padding-top: 4rem;
  }
  @media only screen and (min-width: 992px) {
    padding-left: 10rem;
    padding-right: 10rem;
    padding-top: 4rem;
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
  },
}));

const Layout = ({
  children,
  pageContext: {
    locale,
    featuredImage,
    title,
    list,
    description,
    pathname,
  },
  location,
}) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [theme, setTheme] = useState(theming.defaultTheme);

  return (
    <div className={classes.root} id="reach-skip-nav">
      <ThemeProvider theme={theme}>
        <LocaleContext.Provider
          value={{
            locale,
            featuredImage,
            title,
            list,
            location,
            description,
            pathname,
          }}
        >

          <GlobalStyles />
          <SEO title={title} />
          <Wrapper dir="rtl">
            <Header
              location={location}
              fixed
              color="transparent"
              changeColorOnScroll={{
                height: 100,
                color: 'white',
              }}
              rightLinks={<HeaderLinks />}
              brand="صوت حر"
            />
          </Wrapper>
          <Wrapper dir="rtl">
            <main>
              <CookieConsent
                location="bottom"
                buttonText="Sure man!!"
                cookieName="myAwesomeCookieName2"
                style={{ background: '#2B373B' }}
                buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
                expires={150}
              >
                This website uses cookies to enhance the user experience.
                {' '}
                <span style={{ fontSize: '10px' }}>This bit of text is smaller :O</span>
              </CookieConsent>

              {children}
            </main>
          </Wrapper>
          <Footer />
        </LocaleContext.Provider>
      </ThemeProvider>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
export { LocaleContext };
