// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-author-index-js": () => import("./../../../src/templates/Author/index.js" /* webpackChunkName: "component---src-templates-author-index-js" */),
  "component---src-templates-authors-index-js": () => import("./../../../src/templates/Authors/index.js" /* webpackChunkName: "component---src-templates-authors-index-js" */),
  "component---src-templates-categories-index-js": () => import("./../../../src/templates/Categories/index.js" /* webpackChunkName: "component---src-templates-categories-index-js" */),
  "component---src-templates-postandrelated-js": () => import("./../../../src/templates/postandrelated.js" /* webpackChunkName: "component---src-templates-postandrelated-js" */),
  "component---src-templates-tags-index-js": () => import("./../../../src/templates/Tags/index.js" /* webpackChunkName: "component---src-templates-tags-index-js" */)
}

