import React, { useState, useRef } from 'react';
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { styled } from 'styled-components';
import './tabs.css';
/* eslint-disable */
import {WeatherComponent, Converter} from '../../components';
import ReactWeather from '../../components/Weather/components/ReactWeather';
import { useOpenWeather } from '../../components/Weather/openweather';
import Sticky from 'react-stickynode';
import ReactPlayer from 'react-player';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import LiveTvIcon from '@material-ui/icons/LiveTv';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import EuroIcon from '@material-ui/icons/Euro';
import Typography from '@material-ui/core/Typography';
import screenfull from 'screenfull'


const useStyles = makeStyles((theme) => ({
  innerSticky: {
    width: 'auto',
    '& div': {
      width: 'auto !important',
    }
  },
}));



/* eslint-enable */

// const StyledTab = styled(Tab)``

const RightToLeft = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [sticky, setSticky] = useState(true);
  const player = useRef(null);
  const handleClickFullscreen = () => {
    if (screenfull.isEnabled) {
      screenfull.request(player.current.wrapper);
    }
  };
  const classes = useStyles();
  const disableSticky = () => {
    setSticky(false);
  };
  return (
    <div dir="rtl" style={{ backgroundColor: 'white', padding: '1rem' }}>
      <Tabs
        selectedIndex={tabIndex}
        onSelect={(index) => {
          setTabIndex(index);
          if (index === 1) {
            setSticky(true);
          }
        }}
        direction="rtl"
      >
        <TabList>
          <Tab>
            {' '}
            <Typography
              type="div"
              style={{
                fontFamily: 'Almarai',
                textAlign: 'justify',
              }}
            >
              ثقافة
            </Typography>
          </Tab>
          <Tab>
            <LiveTvIcon style={{ color: 'red' }} />
            <Typography
              type="div"
              style={{
                fontFamily: 'Almarai',
                textAlign: 'justify',
              }}
            >
              فيديوهات
            </Typography>
          </Tab>
          <Tab>
            <EuroIcon />

            <Typography
              type="div"
              style={{
                fontFamily: 'Almarai',
                textAlign: 'justify',
              }}
            >
              الصرف
            </Typography>
          </Tab>
          <Tab>
            <WbSunnyIcon style={{ color: 'orange' }} />
            <Typography
              type="div"
              style={{
                fontFamily: 'Almarai',
                textAlign: 'justify',
              }}
            >
              الطقس
            </Typography>
          </Tab>
        </TabList>
        <TabPanel style={{ textAlign: 'right' }}>

          <Typography
            type="div"
            style={{
              fontFamily: 'Almarai',
              textAlign: 'justify',
            }}
          >
            أن يذكر الشتاء العالم لان, تم دول هامش اكتوبر, أما شدّت تمهيد ويتّفق بل. أم إنطلاق الصفحات دار, أم الشمل الجنرال انه. جيوب وبحلول وإعلان بحق لم. تحت غرّة، الساحل لم.

            بال ان الأول التنازلي, ممثّلة الثقيل بالإنزال بال مع. أي وأزيز وفرنسا استبدال قام, مليون سبتمبر وباستثناء في لكل. في حتى ليبين الأخذ التقليدية, تم جيوب أفاق أدنى انه. هو قِبل إعلان مقاطعة على.

          </Typography>

        </TabPanel>
        <TabPanel style={{ textAlign: 'right', width: 'auto' }}>
          <Sticky enabled={sticky} top={500} bottomBoundary="#footer" innerClass={classes.innerSticky} innerZ={2}>
            {sticky && (
            <CloseIcon
              variant="outlined"
              style={{
                backgroundColor: 'black', size: 20, fill: 'white', position: 'absolute', top: 0, right: 0, cursor: 'pointer',
              }}
              onClick={() => { disableSticky(); }}
            >
              {' '}
              close
            </CloseIcon>
            )}
            <div>
              <ReactPlayer
                ref={player}
                url={[
                  'https://www.youtube.com/watch?v=oUFJJNQGwhk',
                  'https://www.youtube.com/watch?v=jNgP6d9HraI',
                  'https://www.youtube.com/watch?v=m7nvdUEa-4Y',
                ]}
              />
              <button type="submit" onClick={handleClickFullscreen}>Fullscreen</button>
            </div>
          </Sticky>
        </TabPanel>
        <TabPanel style={{ textAlign: 'right' }}>
          <Converter />
        </TabPanel>
        <TabPanel>
          <WeatherComponent />
        </TabPanel>
      </Tabs>
    </div>
  );
};
export default RightToLeft;
