import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

import BackgroundImage from 'gatsby-background-image';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Underlined from '../EmotionCard/Underlined';

const MultiBackground = ({ className }) => {
  const {
    astronaut,
    logo,
    seamlessBackground,

  } = useStaticQuery(
    graphql`
      query {
        astronaut: file(relativePath: { eq: "gatsby-astronaut.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }

        logo: file(relativePath: { eq: "sawthor-logo.png" }) {
          childImageSharp {
            fluid(quality: 60, maxWidth: 120) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        seamlessBackground: file(
          relativePath: { eq: "seamless-background.jpg" }
        ) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 420) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `,
  );

  // Watch out for CSS's stacking order, especially when styling the individual
  // positions! The lowermost image comes last!
  const backgroundFluidImageStack = [
    /* seamlessBackground.childImageSharp.fluid, */
    logo.childImageSharp.fluid,
    /* 'linear-gradient(rgba(220, 15, 15, 0.93), rgba(4, 243, 67, 0.93))', */
    /* 'linear-gradient(to bottom, #315eef, #008cff, #00abf5, #00c2d0, #00d5ab, #1fdf94, #4de775, #78ed4c, #97f14d, #b2f451, #caf857, #e0fb5f)', */
    /* astronaut.childImageSharp.fluid, */

  ].reverse();

  return (
    <BackgroundImage
      Tag="section"
      id="test"
      className={className}
      fluid={backgroundFluidImageStack}
    >
      <StyledInnerWrapper>
        <Typography
          component="h1"
          style={{
            fontFamily: 'Almarai',
            fontSize: '3em',
            fontWeight: 500,
            marginTop: '4em',
          }}
        >

          جريدة إلكترونية ثقافية، فنية، علمية وسياسية مستقلة

        </Typography>

        <Typography
          component="h3"
          style={{
            fontFamily: 'Almarai',
            fontSize: '2em',
            fontWeight: 300,
          }}
        >
          نفضل إعطاء الكلمة للآخر
        </Typography>
      </StyledInnerWrapper>
      <Grid item xs={12} style={{ marginBottom: 20 }}><Underlined /></Grid>
      <Grid item xs={12}><Underlined /></Grid>
    </BackgroundImage>
  );
};

const StyledInnerWrapper = styled.div`

  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
`;

const StyledMultiBackground = styled(MultiBackground)`
  top: 100px;
  width: 100%;
  min-height: 40vh;
  /* You should set a background-size as the default value is "cover"! */
  background-size: auto;
  /* So we won't have the default "lightgray" background-color. */
  background-color: transparent;
  /* Now again, remember the stacking order of CSS: lowermost comes last! */
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-position: center 0%, center, center;
  color: #fff;
`;

export default StyledMultiBackground;
