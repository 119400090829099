import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    /* maxWidth: '36ch', */
    backgroundColor: theme.palette.background.paper,
    marginBottom: theme.spacing(3),
  },
  inline: {
    display: 'inline',
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

export default function AlignItemsList() {
  const classes = useStyles();

  return (
    <List className={classes.root}>
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt="Remy Sharp" variant="circular" className={classes.large} src="https://cdn.pixabay.com/photo/2014/10/23/20/51/iphone-500291_1280.jpg" />
        </ListItemAvatar>
        <ListItemText
          primary="Brunch this weekend?"
          style={{ paddingRight: 20, textAlign: 'right' }}
          secondary={(
            <>
              <Typography
                component="span"
                variant="body2"
                className={classes.inline}
                color="textPrimary"
              >
                Ali Connors
              </Typography>
              {" — I'll be in your neighborhood doing errands this…"}
            </>
          )}
        />
      </ListItem>
      <Divider variant="inset" component="li" />
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt="Travis Howard" variant="circular" className={classes.large} src="https://cdn.pixabay.com/photo/2014/10/23/20/51/iphone-500291_1280.jpg" />
        </ListItemAvatar>
        <ListItemText
          primary="Summer BBQ"
          style={{ paddingRight: 20, textAlign: 'right' }}
          secondary={(
            <>
              <Typography
                component="span"
                variant="body2"
                className={classes.inline}
                color="textPrimary"
              >
                to Scott, Alex, Jennifer
              </Typography>
              {" — Wish I could come, but I'm out of town this…"}
            </>
          )}
        />
      </ListItem>
      <Divider variant="inset" component="li" />
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt="Cindy Baker" variant="circular" className={classes.large} src="https://cdn.pixabay.com/photo/2014/10/23/20/51/iphone-500291_1280.jpg" />
        </ListItemAvatar>
        <ListItemText
          primary="Oui Oui"
          style={{ paddingRight: 20, textAlign: 'right' }}
          secondary={(
            <>
              <Typography
                component="span"
                variant="body2"
                className={classes.inline}
                color="textPrimary"
              >
                Sandra Adams
              </Typography>
              {' — Do you have Paris recommendations? Have you ever…'}
            </>
          )}
        />
      </ListItem>
    </List>
  );
}
