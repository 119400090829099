import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

import BackgroundImage from 'gatsby-background-image';
import Typography from '@material-ui/core/Typography';
import LocalizedLink from '../localizedLink';

const MultiBackground = ({ className }) => {
  const {
    astronaut,
    logo,
    seamlessBackground,

  } = useStaticQuery(
    graphql`
      query {
        astronaut: file(relativePath: { eq: "gatsby-astronaut.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }

        logo: file(relativePath: { eq: "sawthor-logo.png" }) {
          childImageSharp {
            fluid(quality: 40, maxWidth: 30) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        seamlessBackground: file(
          relativePath: { eq: "seamless-background.jpg" }
        ) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 420) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `,
  );

  // Watch out for CSS's stacking order, especially when styling the individual
  // positions! The lowermost image comes last!
  const backgroundFluidImageStack = [
    /* seamlessBackground.childImageSharp.fluid, */
    logo.childImageSharp.fluid,
    /* 'linear-gradient(rgba(220, 15, 15, 0.93), rgba(4, 243, 67, 0.93))', */
    /* 'linear-gradient(to bottom, #315eef, #008cff, #00abf5, #00c2d0, #00d5ab, #1fdf94, #4de775, #78ed4c, #97f14d, #b2f451, #caf857, #e0fb5f)', */
    /* astronaut.childImageSharp.fluid, */

  ].reverse();

  return (
    <BackgroundImage
      Tag="section"
      id="logo"
      className={className}
      fluid={backgroundFluidImageStack}
      style={{ display: 'none' }}
    />
  );
};

const StyledInnerWrapper = styled.div`

  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
`;

const StyledMultiBackground = styled(MultiBackground)`
  top: 0;
  width: 100%;
  /*min-height: 70px;*/
  padding: 0;
  margin: 0;
  height: 2rem;
  margin-left: 70px;
  /* You should set a background-size as the default value is "cover"! */
  background-size: 2rem;
  /* So we won't have the default "lightgray" background-color. */
  background-color: transparent;
  /* Now again, remember the stacking order of CSS: lowermost comes last! */
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-position: center 100%, center, center;
  color: #fff;
`;

export default StyledMultiBackground;
